import { useQuery} from '@apollo/client';
import { Queries } from "./common_queries";

export const People_Email = (email_id) => {
    return useQuery(Queries.People_Email,{
        variables: {email_id: email_id}
    });
}

export const People_NegoID = (nego_id) => {
    return useQuery(Queries.People_NegoID,{
        variables: {nego_id: nego_id}
    });
}

export const Office_ID = (office_id) => {
    return useQuery(Queries.Office_ID,{
        variables: {office_id: office_id}
    });
}

export const Office_ID1 = (office_id) => {
    return useQuery(Queries.Office_ID1,{
        variables: {office_id: office_id}
    });
}

export const Similar_Jobs = (job_id) => {
    return useQuery(Queries.Similar_Jobs,{
        variables: {id: job_id}
    });
}

export const Similar_Newhomes = (id) => {
    return useQuery(Queries.Similar_Newhomes,{
        variables: {id: id}
    });
}

export const Newhomes_Properties = (id) => {
    return useQuery(Queries.Newhomes_Properties,{
        variables: {id: id}
    });
}

export const NoResultProperties = (status, department) => {
    return useQuery(Queries.NoResultProperties,{
        variables: {status: status, department: department}
    });
}

export const FutureAuctions = (status, department) => {
    return useQuery(Queries.FutureAuctions,{
        variables: {status: status, department: department}
    });
}

export const NoNewHomes = () => {
    return useQuery(Queries.NoNewHomes);
}

export const PopularSearch = (alias) => {
    return useQuery(Queries.PopularSearch,{
        variables: {alias: alias}
    });
}

export const OfficeList = (alias) => {
    return useQuery(Queries.OfficeList);
}

export const Property_Data=(office_crm_id)=>{
    return useQuery(Queries.Property_Data,{
        variables: {office_crm_id: office_crm_id}
    });
}

export const Property_Datas=(crm_office_id)=>{
    return useQuery(Queries.Property_Datas,{
        variables: {crm_office_id: crm_office_id}
    });
}