import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useWindowSize } from "../../../hooks/window-size"
import { Container, Row, Col, Button } from "react-bootstrap"
import "./Cta.scss"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
  fadeInFromLeft,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import Modal from "react-bootstrap/Modal"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import NewHomesForm from "../../forms/newhomescontact"

const btnInfoMobile = [
  {
    id: 1,
    text: "Contact DNG",
    link: "/",
  },
  {
    id: 2,
    text: "Find a Service",
    link: "/",
  },
  {
    id: 3,
    text: "Valuation",
    link: "/",
  },
  {
    id: 4,
    text: "Find a Property",
    link: "/",
  },
]
const btnInfoDesktop = [
  {
    id: 1,
    text: "I want to contact DNG",
    link: "/",
  },
  {
    id: 2,
    text: "I want to find a service",
    link: "/",
  },
  {
    id: 3,
    text: "I want to sell or let my property",
    link: "/",
  },
  {
    id: 4,
    text: "I want to find a property",
    link: "/",
  },
]

const Cta = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        staticTeamDetailsGetStarted(publicationState: LIVE) {
          Get_Started_Team_Detail {
            Content
            Design_Title
            Title
            Get_Started_Cta_Links {
              CTA_Label
              Link_Type
              CTA_Url {
                Alias
              }
            }
          }
        }
      }
    }
  `)
  const [windowWidth] = useWindowSize()
  const [btnText, setBtnText] = useState(btnInfoMobile)
  const [show, setShow] = useState(false)


  useEffect(() => {
    if (windowWidth > 767) {
      setBtnText(btnInfoDesktop)
    } else {
      setBtnText(btnInfoMobile)
    }
  }, [windowWidth])

  //console.log(props, "prrrrrops")
  const handleClose = () => setShow(false)
  const handleModal=()=>{
    setShow(true)
  }


  return props.static ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="cta"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col>
                {data?.glstrapi?.staticTeamDetailsGetStarted
                  ?.Get_Started_Team_Detail?.Title ? (
                  <motion.h3 variants={fadeInFromLeft}>
                    {
                      data?.glstrapi?.staticTeamDetailsGetStarted
                        ?.Get_Started_Team_Detail?.Title
                    }
                  </motion.h3>
                ) : data?.glstrapi?.staticTeamDetailsGetStarted
                    ?.Get_Started_Team_Detail?.Design_Title ? (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown
                      source={
                        data?.glstrapi?.staticTeamDetailsGetStarted
                          ?.Get_Started_Team_Detail?.Design_Title
                      }
                      allowDangerousHtml
                    />
                  </motion.div>
                ) : null}
                {data?.glstrapi?.staticTeamDetailsGetStarted
                  ?.Get_Started_Team_Detail?.Content && (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown
                      source={
                        data?.glstrapi?.staticTeamDetailsGetStarted
                          ?.Get_Started_Team_Detail?.Content
                      }
                      allowDangerousHtml
                    />
                  </motion.div>
                )}
                <div className="btn-wrapper d-flex flex-wrap">
                  {data?.glstrapi?.staticTeamDetailsGetStarted?.Get_Started_Team_Detail?.Get_Started_Cta_Links?.map(
                    (btnText, index) => (
                      <motion.div
                        className="btn-wrap"
                        key={index}
                        custom={index}
                        variants={delayItemsFromBottom}
                      >
                        <GetURL
                          class="btn"
                          Link_Type={btnText.Link_Type}
                          label={btnText.CTA_Label}
                          alias={btnText.CTA_Url?.Alias}
                        />
                      </motion.div>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  ) : (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="cta start"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col>
                {props.Title ? (
                  <motion.h3 variants={fadeInFromLeft}>{props.Title}</motion.h3>
                ) : props.Design_Title ? (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown
                      source={props?.Design_Title}
                      allowDangerousHtml
                    />
                  </motion.div>
                ) : null}
                {props.Content && (
                  <motion.div variants={fadeInFromLeft}>
                    <ReactMarkdown source={props?.Content} allowDangerousHtml />
                  </motion.div>
                )}
                <div className="btn-wrapper d-flex flex-wrap">
                  {props.Get_Started_Cta_Links?.map((btnText, index) => (
                  
                    <motion.div
                      className="btn-wrap"
                      key={index}
                      custom={index}
                      variants={delayItemsFromBottom}
                    >
                      {btnText.CTA_Label === "download our brochure" ? (
                        <GetURL
                          class="btn"
                          Link_Type="External_url"
                          label={btnText.CTA_Label}
                          alias="https://drive.google.com/file/d/1-4qt7LWI-OlJ0_kkpzVPvcfS4I9lHWCR/view?usp=sharing"
                        />
                      ) :
                      
                        btnText.CTA_Label === "Browse our New Developments" ? (
                          <GetURL
                            class="btn"
                            Link_Type={btnText.Link_Type}
                            label={btnText.CTA_Label}
                            alias="new-homes-for-sale"
                          />
                      ):
                      btnText.Model_View?
                      <>
                          <Button onClick={()=>handleModal()}>{btnText.CTA_Label}</Button>
                          <Modal
                            show={show}
                            centered={true}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                          >
                            <Modal.Header
                              closeButton
                              className="contact-close-btn"
                            >
                              <Modal.Title className="w-100">
                                Email to New Homes
                                
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="form">
                             <NewHomesForm ctaLabel={btnText.CTA_Label}/>
                            </Modal.Body>
                          </Modal>
                      </>    
                     : (
                        <GetURL
                          class="btn"
                          Link_Type={btnText.Link_Type}
                          label={btnText.CTA_Label}
                          alias={btnText.CTA_Url?.Alias}
                        />
                      )}
                    </motion.div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}

export default Cta
