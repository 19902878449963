import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
import Slider from "react-slick"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
// import { useWindowSize } from "../../../hooks/window-size"
import { useWindowSize } from "../../hooks/window-size"
import { Similar_Jobs } from "../../queries/common_use_query"

import { URLS } from "../common/site/constants"

import "./RelatedJobs.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const RelatedJobs = props => {
  const MotionCol = motion(Col)
  const MotionRow = motion(Row)
  const [windowWidth] = useWindowSize()

  const { data } = Similar_Jobs(props.job_id)

  // const data = [
  //   {
  //     role: "Senior Sales Negotiator",
  //     place: "Head Office, Dublin",
  //     type: "Residential & Commercial",
  //   },
  //   {
  //     role: "Senior Sales Negotiator",
  //     place: "Head Office, Dublin",
  //     type: "Residential & Commercial",
  //   },
  //   {
  //     role: "Senior Sales Negotiator",
  //     place: "Head Office, Dublin",
  //     type: "Residential & Commercial",
  //   },
  //   {
  //     role: "Senior Sales Negotiator",
  //     place: "Head Office, Dublin",
  //     type: "Residential & Commercial",
  //   },
  //   {
  //     role: "Senior Sales Negotiator",
  //     place: "Head Office, Dublin",
  //     type: "Residential & Commercial",
  //   },
  // ]

  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          infinite: data?.careers?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          infinite: data?.careers?.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: data?.careers?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: data?.careers?.length > 1 ? true : false,
        },
      },
    ],
  }

  //console.log("data ==>", data)
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="our-advantage-block related_jobs"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          {data?.careers?.length > 0 && (
            <Container>
              <Row>
                <Col lg="12">
                  <motion.h2 variants={contentItem}>
                    Other Vacant Positions
                  </motion.h2>
                </Col>
              </Row>
              {windowWidth < 1199 && (
                <div>
                  <Slider {...settings}>
                    {data?.careers?.map((item, index) => (
                      <motion.div
                        key={index}
                        custom={index}
                        variants={delayItemsFromBottom}
                        className="advantage-item"
                      >
                        <div className="advantage-info">
                          <h3>
                            <Link to={`${URLS.Careers.alias}/${item.URL}/`}>
                              {item.Job_Title}
                            </Link>
                          </h3>
                          {item.office && (
                            <div className="location">
                              <i className="icon-pin-light-red"></i>
                              <span className="location-name">
                                Head Office, {item?.office?.County}
                              </span>
                            </div>
                          )}

                          {item?.department && (
                            <div className="residents">
                              <i className="icon-building"></i>
                              <span className="residents-details">
                                {item?.department?.Name}
                              </span>
                            </div>
                          )}
                          <div className="view-details">
                            <Link to={`${URLS.Careers.alias}/${item.URL}/`}>
                              + View Details
                            </Link>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </Slider>
                </div>
              )}
              {windowWidth > 1199 && (
                <Row>
                  <Slider {...settings}>
                    {data?.careers?.map((item, index) => (
                      <MotionCol
                        key={index}
                        custom={index}
                        variants={contentItem}
                        md="6"
                        xl="3"
                        className="d-flex mb-space our-explore"
                      >
                        <div className="advantage-item">
                          <div className="advantage-info related-services">
                            <h3>
                              <Link to={`${URLS.Careers.alias}/${item.URL}/`}>
                                {item.Job_Title}
                              </Link>
                            </h3>

                            {item.office && (
                              <div className="location">
                                <i className="icon-pin-light-red"></i>
                                <span className="location-name">
                                  Head Office, {item?.office?.County}
                                </span>
                              </div>
                            )}

                            {item?.department && (
                              <div className="residents">
                                <i className="icon-building"></i>
                                <span className="residents-details">
                                  {item?.department?.Name}
                                </span>
                              </div>
                            )}

                            <div className="view-details">
                              <Link to={`${URLS.Careers.alias}/${item.URL}/`}>
                                + View Details
                              </Link>
                            </div>
                          </div>
                        </div>
                      </MotionCol>
                    ))}
                  </Slider>
                </Row>
              )}
            </Container>
          )}
        </motion.div>
      )}
    </InView>
  )
}

export default RelatedJobs
