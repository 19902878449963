import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Cta from "@Components/Home/Cta/Cta"
// //import ScrollAnimation from "react-animate-on-scroll"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"
import { inViewOptions } from "../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import logoBlack from "../../images/logo.svg"
import NewsBanner from "@Components/Static/NewsBanner/NewsBanner"
import ContactSidebarCareer from "@Components/Static/ContactSidebar/ContactSidebarCareer"
import OpinionList from "@Components/Static/OpinionList/OpinionList"
import ReactMarkdown from "react-markdown"
import RelatedJobs from "./RelatedJobs"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../../pages/404"
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4,
    },
  },
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const CareersDetails = props => {
  
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }

  const data = props.data?.glstrapi;
  if (data.careers && data.careers?.length > 0) {
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <Layout className="blog-page" Alias={"careers"}>
                {data?.careers?.map((Page, i) => {
                  return (
                    <>
                      <SEO
                        title={Page ? Page.Job_Title : ""}
                        description={Page ? Page.Job_Title : ""}
                      />
                      <Helmet
                        bodyAttributes={{
                          class: `templates-blog-details-template careers-details`,
                        }}
                      />
                      <Breadcrumbs
                        Page={Page?.Job_Title}
                        class="static"
                        alias={"careers"}
                        type="details-page"
                      />
                      <div className="static-block">
                        <Container>
                          <Row>
                            <Col xl="7" className="col-xxl-8 col-custom-8">
                              <h1>{Page?.Job_Title}</h1>
                              <div className="offices">
                                <i className="icon-pin-light-red"></i>{" "}
                                {Page?.office?.Address}
                                <br />
                                <i className="icon-building"></i>{" "}
                                {Page?.department?.Name}
                              </div>

                              <motion.div className="ContentBlock careers">
                                {Page?.Job_Detail && (
                                  <>
                                    <ReactMarkdown
                                      source={Page.Job_Detail}
                                      allowDangerousHtml
                                    />
                                  </>
                                )}
                              </motion.div>
                            </Col>
                            <Col xl="5" className="col-xxl-4 col-custom-4">
                              <div className="sidebar-bk">
                                <ContactSidebarCareer
                                  shareurl={shareurl}
                                  Job_Title={Page?.Job_Title}
                                  Contact_No={Page.Contact_No}
                                  Choose_Team={Page.Choose_Team}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>

                      <div className="related-jobs-block">
                        <RelatedJobs job_id={Page?.id} />
                      </div>
                    </>
                  )
                })}
                <Cta static="true" />
              </Layout>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  )} else{(
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )}
}

export default CareersDetails

export const pageQuery = graphql`
  query CareersQuery($slug: String!) {
    glstrapi { 
      careers(where: { URL: $slug }) {
        id
        Job_Title
        Job_Detail
        URL
        Contact_No
        office {
          Office_Name
          Address
        }
        department {
          Name
        }
        Choose_Team {
          id
          imagetransforms
          Designation
          Name
          Phone
          Email
          URL
          Image {
            alternativeText
            url
          }
        }
      } 
    }
  }
`
