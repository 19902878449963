import React, { useState } from "react"
import { Link } from "gatsby"
import contactImg1 from "../../../images/contact-4.jpg"
import contactImg2 from "../../../images/contact-5.jpg"
import "./ContactSidebarCareer.scss"
import GetURL from "@Components/common/site/get-url"
import SocialShare from "@Components/Share/SocialShare"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import CareerForm from "@Components/forms/careers"
import NoImage from "../../../images/no-image.png"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const ContactSidebarCareer = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }
  const [showjob, setShowjob] = useState(false)
  const handleClosejob = () => setShowjob(false)
  const handleShowjob = event => {
    setShowjob(true)
  }
  let processedImages = JSON.stringify({})
  if (props.Choose_Team?.imagetransforms?.Image_Transforms) {
    processedImages = props.Choose_Team?.imagetransforms.Image_Transforms
  }
  return (
    <div className="sidebar-contact-career">
      <div className="dealer-cta d-xl-flex bottom-fixed-btn call-bk">
        <div className="btn-wrap">
          <a class="btn" href="javascript:;" onClick={() => handleShowjob()}>
            apply
          </a>
        </div>
        <div className="btn-wrap">
          <SocialShare Title="share job" />
        </div>
        <span className="contact-no">
          <span className="desk-only">or call us on </span>
          <a className="call-icon-only" href={"tel:" + props.Contact_No}>
            <span className="desk-only">{props.Contact_No}</span>
          </a>
        </span>
      </div>
      <div className="dealer-info">
        <div className="dealer-wrap">
          <div className="img-wrap">
            {/* <img src={props.Choose_Team?.Image?.url} alt={props.Choose_Team?.Image?.alternativeText} /> */}
            {props.Choose_Team?.Image?.url ? (
              <ImageTransform
                imagesources={props.Choose_Team?.Image?.url}
                renderer="srcSet"
                imagename="team.Image.smalltail"
                attr={{
                  alt: props.Choose_Team?.Image?.alternativeText
                    ? props.Choose_Team?.Image?.alternativeText +
                      " - DNG Estate Agents"
                    : props.Choose_Team?.Name + " - DNG Estate Agents",
                  className: "",
                }}
                imagetransformresult={processedImages}
                id={props.Choose_Team?.id}
              ></ImageTransform>
            ) : (
              <img
                src={NoImage}
                alt={props.Choose_Team?.Image?.alternativeText}
              />
            )}
          </div>
          <div className="dealer-right">
            <span className="dealer-name">{props.Choose_Team?.Name}</span>
            <span className="dealer-position">
              {props.Choose_Team?.Designation}
            </span>
            <span className="dealer-contact">
              {/* <a href={"tel:"+props.Choose_Team?.Phone}>{props.Choose_Team?.Phone}</a> /  */}
              <a href="javascript:;" onClick={() => handleShow()}>
                Email
              </a>
            </span>
          </div>
        </div>
        <Modal
          size="lg"
          show={show}
          centered={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
        >
          <Modal.Header closeButton className="contact-close-btn">
            <Modal.Title className="w-100">
              Email to {props.Choose_Team?.Name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="form">
            <TeamForm
              toemail={props.Choose_Team?.Email}
              officeNumber={props.Choose_Team?.Phone}
              teamMember={props.Choose_Team?.Name}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showjob}
          centered={true}
          onHide={handleClosejob}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
        >
          <Modal.Header closeButton className="contact-close-btn">
            <Modal.Title className="w-100">
              Apply to {props.Job_Title} job
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="form">
            <CareerForm
              shareurl={props.shareurl}
              Job_Title={props.Job_Title}
              toemail={props.Choose_Team?.Email}
              formName="Apply for Job"
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default ContactSidebarCareer
