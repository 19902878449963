import gql from "graphql-tag"

const People_Email = gql`
query GetPeopleEmail($email_id: String!){
    teams(where:{Email:$email_id}){
        Name
        Email
        Phone
        Designation
        id
        URL
        Image {
          url
          alternativeText
        }
    }
}`

const Property_Data=gql`
query GetPropertyData($office_crm_id: String!){
    properties(where:{office_crm_id:$office_crm_id,publish:true}){
        department
        slug
        status
    }
}
`

const People_NegoID = gql`
query GetPeopleEmail($nego_id: String!){
    teams(where:{Nego_Id:$nego_id}){
        Name
        Email
        Phone
        Designation
        id
        URL
        Image {
          url
          alternativeText
        }
    }
}`

const Property_Datas=gql`
query GetPropertyDatas($crm_office_id: String!){
    properties(where:{office_crm_id:$crm_office_id,publish:true}){
        id
        crm_id
        crm_provider
        status
        department
        search_type
        address
        special
        price
        building
        price_qualifier
        bedroom
        bathroom
        reception
        crm_negotiator_id
        office_crm_id
        office_crm_id1
        display_address
        title
        description
        slug
        images
        imagetransforms
        publish
        latitude
        longitude
        room_details
        accomadation_summary
        virtual_tour
        video_tour
        floorplan
        floorarea_min
        floorarea_max
        floorarea_type
        brochure
        extras
    }
}
`

const Office_ID = gql`
query GetOfficeID($office_id: String!){
    offices(where:{crm_office_id:$office_id}){
        id
        Office_Name
        Email
        URL
        Address
        Contact_No
        Licence_Number
        crm_office_id
        my_home_id
        Banner_Image
        {
          url
          alternativeText
        }
    }
}`

const Office_ID1 = gql`
query GetOfficeID($office_id: String!){
    offices(where:{my_home_id:$office_id}){
        id
        Office_Name
        Email
        URL
        Address
        Contact_No
        Licence_Number
        crm_office_id
        my_home_id
        Banner_Image
        {
          url
          alternativeText
        }
    }
}`

const Similar_Jobs = gql`
query Similar_Jobs($id: ID!){
    careers(sort:"createdAt:desc",where:{id_nin:$id}){
        id
        Job_Title
        URL
        department{
            id
            Name
        }
        office{
            id
            Office_Name
            County
            Branch_Location
        }
    }
}`

const Similar_Newhomes = gql`
query Similar_Newhomes($id: ID!){
    newDevelopments(sort:"createdAt:desc", limit:9, where:{id_nin:$id, publish:true}){
        id
        crm_id
        type
        status
        search_type
        price
        price_qualifier
        bedroom
        display_address
        address
        description
        title
        slug
        images
        town
        district
        country
        imagetransforms
        publish
        crm_negotiator_id
        office_crm_id
        developer_logo{
            url
            alternativeText
        }
        developer
    }
}`

/* ========== New development Properties with CRM_ID ======== */
const Newhomes_Properties_Crm_ID = gql`
query Newhomes_Properties($crm_id: ID!){
    properties(sort:"createdAt:desc", limit:9, where:{master_id:$crm_id, publish:true}){
        id
        display_address
        crm_id
        title
        bedroom
        slug
        price
        price_qualifier
        department
        status
        search_type
        address
        images
        imagetransforms   
    }
}`

/* ========== New development Properties with Development_ID ======== */
const Newhomes_Properties = gql`
query Newhomes_Properties($id: ID!){
    properties(sort:"createdAt:desc", limit:9, where:{new_development:{id:$id}, publish:true}){
        id
        display_address
        crm_id
        title
        bedroom
        slug
        price
        price_qualifier
        department
        status
        search_type
        address
        images
        imagetransforms   
    }
}`

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, where:{status_contains:$status, department_contains:$department, publish:true, search_type_nin:["sales_lettings"]}) {
        id
        display_address
        address
        search_type
        status
        slug
        title
        search_type
        department
        description
        building
        price
        images
        imagetransforms
        floorarea_min
        floorarea_max
        floorarea_type
        floorplan
        video_tour
        virtual_tour
        price_qualifier
        bedroom
        bathroom
        reception
        extras
        lotDetailsUrl
    }
}`

/* ========== Future Auctions Properties ======== */
const FutureAuctions = gql`
query FutureAuctions($status: String!, $department: String!){
    properties(where:{status:$status, department:$department, publish:true}) {
        id
        display_address
        crm_id
        extras
    }
}`

/* ========== No New Homes ======== */
const NoNewHomes = gql`
query NoNewHomes{
    newDevelopments(sort:"createdAt:desc", limit:6, where:{publish:true}){
        id
        crm_id
        status
        search_type
        price
        price_qualifier
        bedroom
        display_address
        title
        slug
        images
        imagetransforms
        publish
        floorplan
        description
        negotiator_details:crm_negotiator_id
    }
}`

/* ========== Popular Search ======== */
const PopularSearch = gql`
query PopularSearch($alias: String!){
    allMenus(where:{Alias:$alias}){
        id
        Label
        Popular_Search_Component{
          Popular_Search_List{
            popular_search{
              Name
              Content
            }
          }
        }
      }
}`

/* ========== Office List ======== */
const OfficeList = gql`
query OfficeList{
    offices{
        id
        Office_Name
        Branch_Location
        County
        Email
        URL
        Address
        Contact_No
        crm_office_id
        Banner_Image
        {
          url
          alternativeText
        }
        Thumbnail
        {
          url
          alternativeText
        }
        imagetransforms
        Map_Pointer_Left_Position_Percentage
        Map_Pointer_Top_Position_Percentage
    }
}`

export const Queries = {
    People_Email,
    People_NegoID,
    Office_ID,
    Office_ID1,
    Similar_Jobs,
    Similar_Newhomes,
    Newhomes_Properties,
    NoResultProperties,
    NoNewHomes,
    FutureAuctions,
    PopularSearch,
    OfficeList,
    Property_Data,
    Property_Datas
};